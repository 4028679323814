import LOGO from "../assets/image/logo.png";
import TITLE from "../assets/image/title.png";
import BG_REWARD from "../assets/image/bg-reward.png";
import DISABLE from "../assets/image/disablebg.png";
import PLAY from "../assets/image/play.png";
import FINISH from "../assets/image/finished.png";
import RESULT from "../assets/image/result.png";
import BG_POINT from "../assets/image/bg-point.png";
import { useNavigate, useParams } from "react-router-dom";
import BUTTON_ROTATION from "../assets/image/button-rotation.png";
import BACK from "../assets/image/back.png";
import BTN_COUNT from "../assets/image/button-count.png";
import { Fragment, useEffect, useState } from "react";
import { homeServices } from "../services/apiService/homeService";
import { ReponseDetailsSpinModel } from "../models/ListRewardsModel";
import Popup from "../components/Popup";
import LOGO2 from "../assets/image/logo-blue.png";
import TITLE2 from "../assets/image/title-2.png";
import QUAY2 from "../assets/image/quay2.png";
import { setAuthorization } from "../services/apiService/configURL";

export default function DetailsReward() {
  const token = localStorage.getItem("TOKEN");

  const navigation = useNavigate();
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [disableAll, setDisableAll] = useState<number>(0);
  const [disable, setDisable] = useState(false);
  const [spinDetails, setSpinDetails] = useState<ReponseDetailsSpinModel>();
  const [wheelRemaining, setWheelRemaining] = useState<number>(0);
  const [lstReward, setLstRewards] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const { id, type } = useParams();
  const handleDetails = () => {
    setDisable(true);
    let lastNumber1 = 0;
    let lastNumber2 = 0;
    let lastNumber3 = 0;
    if (!disable) {
      let numbers1 = [
        0, 4, 3, 3, 5, 8, 6, 3, 4, 5, 2, 1, 8, 9, 8, 4, 6, 8, 7, 8, 0, 3, 2, 6,
        5, 1, 2, 3, 5, 7, 8, 9, 6, 1, 7,
      ];
      let numbers2 = [
        6, 1, 2, 3, 4, 5, 4, 3, 2, 1, 2, 3, 5, 7, 8, 4, 6, 8, 9, 0, 1, 2, 4, 3,
        2, 1, 2, 3, 5, 7, 8, 4, 6, 8, 9,
      ];
      let numbers3 = [
        8, 2, 6, 3, 2, 4, 7, 6, 5, 3, 0, 5, 4, 8, 4, 6, 8, 8, 7, 5, 3, 0, 1, 2,
        1, 2, 7, 5, 0, 1, 2, 6, 9, 1, 8,
      ];
      if (type === "vq1") {
        homeServices
          .UpdateRewards(id as string)
          .then((res: any) => {
            console.log(res);
            setDisableAll(res?.result?.amount_remaining);
            lastNumber1 = res?.result?.coded[0];
            numbers1.push(lastNumber1);

            lastNumber2 = res?.result?.coded[1];
            numbers2.push(lastNumber2);

            lastNumber3 = res?.result?.coded[2];
            numbers3.push(lastNumber3);
            setPeriod(res?.result?.period);
            setWheelRemaining(res?.result?.amount_remaining);
            let index = 0;
            let interval = setInterval(() => {
              setCount1(numbers1[index]);
              setCount2(numbers2[index]);
              setCount3(numbers3[index]);
              index++;
              if (index === numbers1.length) {
                clearInterval(interval);
              }
            }, 3000 / (numbers1.length - 1));
            setTimeout(() => {
              setDisable(false);
              setLstRewards(res?.result?.lst_rewared);
            }, 3000);
            return () => clearInterval(interval);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        homeServices
          .UpdateVq2Rewards(parseInt(id as string))
          .then((res: any) => {
            console.log(res);
            setDisableAll(res?.result?.amount_remaining);
            lastNumber1 = res?.result?.coded[0];
            numbers1.push(lastNumber1);

            lastNumber2 = res?.result?.coded[1];
            numbers2.push(lastNumber2);

            lastNumber3 = res?.result?.coded[2];
            numbers3.push(lastNumber3);
            setPeriod(res?.result?.period);
            setWheelRemaining(res?.result?.amount_remaining);
            let index = 0;
            let interval = setInterval(() => {
              setCount1(numbers1[index]);
              setCount2(numbers2[index]);
              setCount3(numbers3[index]);
              index++;
              if (index === numbers1.length) {
                clearInterval(interval);
              }
            }, 3000 / (numbers1.length - 1));
            setTimeout(() => {
              setDisable(false);
              setLstRewards(res?.result?.lst_rewared);
            }, 3000);
            return () => clearInterval(interval);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      console.log(1);
    }
  };

  useEffect(() => {
    setAuthorization(token as string);
    homeServices
      .DetailsRewards(id as string)
      .then((res: any) => {
        console.log(res);
        setSpinDetails(res?.result);
        setWheelRemaining(res?.result?.amount_remaining);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    homeServices
      .DetailsPeriodRewards(id as string)
      .then((res: any) => {
        setLstRewards(res?.result?.lst_rewared);
        console.log(res);
        setPeriod(res?.result?.period);
        setDisableAll(res?.result?.amount_remaining);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBack = () => {
    navigation(`/${type}`);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenResult = () => {
    setIsOpen(!isOpen);
  };
  const handleFinished = () => {
    navigation(`/${type}`);
  };
  return (
    <Fragment>
      {!isOpen ? (
        <div
          className={`${type === "vq1" ? "bg-desktop" : "bg-vq2"}  relative`}
        >
          <div className="absolute top-10 px-14 flex justify-between w-full">
            <div className="flex items-center">
              <button className="w-16" onClick={handleBack}>
                <img src={BACK} />
              </button>
              <div
                className={`${
                  type === "vq1" ? "text-white" : "text-[#175FAB]"
                } ml-2 uppercase font-semibold-so text-[22px]`}
              >
                Trở về
              </div>
            </div>

            <button className="w-36 relative" onClick={handleOpenResult}>
              <img src={RESULT} />
              <div className="absolute text-[20px] top-[45%] text-white -translate-y-1/2 left-1/2 -translate-x-1/2">
                Kết quả
              </div>
            </button>
          </div>
          <div className="w-full flex justify-center pt-3">
            <img src={type === "vq1" ? LOGO : LOGO2} />
          </div>
          <div className="w-full flex justify-center pt-5">
            <img src={type === "vq1" ? TITLE : TITLE2} className="w-3/12" />
          </div>

          <div className="relative top-0 w-full">
            <div className="absolute top-5 right-[20%] -translate-x-1/2">
              <div className="grid gap-10 grid-cols-3 font-medium-so">
                <div className="w-40 h-60 text-[180px] bg-white rounded-2xl flex justify-center items-center border-number">
                  {count1}
                </div>
                <div className="w-40 h-60 text-[180px] bg-white rounded-2xl flex justify-center items-center border-number">
                  {count2}
                </div>{" "}
                <div className="w-40 h-60 text-[180px] bg-white rounded-2xl flex justify-center items-center border-number">
                  {count3}
                </div>
              </div>
            </div>
            <div className="absolute right-0 flex justify-end">
              <div className="relative w-9/12 right-0">
                <img src={wheelRemaining === 0 ? DISABLE : BG_REWARD} />
                <div className="absolute top-4">
                  <div className="relative left-1/2 -translate-x-1/2 w-10/12 sm:w-6/12 2xl:w-7/12 min-[2000px]:w-7/12">
                    <img src={spinDetails?.gift_image} className="" />
                    <div className="w-full text-center -mt-6 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {spinDetails?.gift_name}
                      </div>
                      <div className="-mt-1">
                        Còn{" "}
                        <span className="font-bold-so text-[30px] text-[#EA2428]">
                          {wheelRemaining}
                        </span>{" "}
                        giải
                      </div>
                      {spinDetails?.status_period ? (
                        <button
                          className="mt-[5%] relative w-32"
                          onClick={disableAll === 0 ? () => {} : handleDetails}
                        >
                          <img src={BTN_COUNT} />
                          <div className="absolute text-[25px] text-[#175FAB] font-semibold-so top-[40%] -translate-y-1/2 w-full flex justify-center">
                            Lần {period}
                          </div>
                        </button>
                      ) : (
                        <button
                          className="mt-[5%] relative w-32"
                          onClick={disableAll === 0 ? () => {} : handleDetails}
                        >
                          <img src={type === "vq1" ? BUTTON_ROTATION : QUAY2} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>{" "}
                <button
                  className="relative w-full flex justify-center -mt-16 hover:pointer"
                  onClick={disableAll === 0 ? handleFinished : handleDetails}
                >
                  <img
                    src={disableAll === 0 ? FINISH : PLAY}
                    className="w-8/12"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-2">
            <div className="relative w-[70%]">
              <img src={BG_POINT} className="relative" />
              <div className=" text-center absolute w-full text-[#EA2428] top-5 left-1/2 -translate-x-1/2">
                <div className="text-[28px] font-semibold-so">
                  CHÚC MỪNG QUÝ KHÁCH TRÚNG THƯỞNG
                </div>
                <div className="text-[27px] font-semibold-so -mt-2">
                  có mã số sau
                </div>
                <div className="grid grid-cols-10 gap-4 px-10 relative top-[3px]">
                  {lstReward?.map((res: any) => (
                    <div className="col-span-1 px-2">
                      <div className="bg-[#175FAB] py-2 rounded-lg text-white text-[18px] font-semibold-so">
                        {res?.code}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Popup
          handleClose={handleOpenResult}
          status={spinDetails?.status_period}
        />
      )}
    </Fragment>
  );
}
