import { UAParser } from "ua-parser-js";
export let userAgent = window.navigator.userAgent,
  platform = window.navigator.platform,
  macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  iosPlatforms = ["iPhone", "iPad", "iPod"],
  os = "";

export const DEVICE = {
  MACOS: "Mac OS",
  WINDOWS: "Windows",
  LINUX: "Linux",
  IOS: "iOS",
  ANDROID: "Android",
};
export function getOS() {
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = DEVICE.ANDROID;
  } else if (!os && /Linux/.test(platform)) {
    os = DEVICE.LINUX;
  }
  return os;
}

export function getDeviceInfo() {
  let parser = new UAParser();
  let info = parser.getResult();
  return info;
}

export function isMobileDevice() {
  let os = getOS();
  return os === DEVICE.ANDROID || os === DEVICE.IOS;
}

export function isLocalhost() {
  const host = window.location.origin;
  return host.includes("localhost");
}
