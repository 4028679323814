import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { homeServices } from "../../services/apiService/homeService";
import { homeLocal } from "../../services/localService/localService";

const initialState = {
  homeData: homeLocal.get() || "",
} as any;

export const getAllBannerHome = createAsyncThunk("home/getHome", async () => {
  const res = await homeServices.homeApi();
  return res;
});

const homeReducer = createSlice({
  name: "user",
  initialState,
  reducers: {},
});
export const homeBanner = (state: any) => state.homeSlice;

export default homeReducer.reducer;
