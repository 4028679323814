import { Fragment, useEffect, useState } from "react";
import "../assets/css/bgResult.css";
import { homeServices } from "../services/apiService/homeService";
import { useParams } from "react-router-dom";
import BTN from "../assets/image/button-count.png";
import CLOSE from "../assets/image/close.png";
import { GiftCode, ListRewardModel } from "../models/ListRewardsModel";

export default function Popup({
  handleClose,
  status,
}: {
  handleClose: () => void;
  status: Boolean | undefined;
}) {
  const { id } = useParams();
  const [lstReward, setLstReward] = useState<any>();
  useEffect(() => {
    homeServices
      .DetailsListRewards(id as string)
      .then((res: any) => {
        console.log(res);
        setLstReward(res?.result?.lst_rewared);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-result relative px-12 py-20">
      <button className="absolute top-0 right-0">
        <img src={CLOSE} onClick={handleClose} className="w-14" />
      </button>
      <div className="grid grid-cols-12 gap-5 top-0">
        {lstReward?.map((res: ListRewardModel) => (
          <Fragment>
            {status && (
              <div className="col-span-1">
                <button className="relative">
                  <img src={BTN} />
                  <div className="absolute font-bold-so text-[20px] w-full left-1/2 -translate-x-1/2 top-[45%] -translate-y-1/2 text-[#175FAB]">
                    Lần {res.period}
                  </div>
                </button>
              </div>
            )}

            <div className={`${status ? "col-span-11" : "col-span-12"}`}>
              <div className="grid grid-cols-12 gap-2">
                {res?.lst?.map((res: GiftCode) => (
                  <div className="bg-[#175FAB] text-[#ffffff] h-12 border-giftcode rounded-xl text-[28px] text-center font-bold-so">
                    {res.code}
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
